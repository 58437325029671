<!--
 * @Author: fengjiahua fenjiahua@qq.com
 * @Date: 2024-05-21 16:02:42
 * @LastEditors: fengjiahua fenjiahua@qq.com
 * @LastEditTime: 2024-09-04 14:54:20
 * @FilePath: \qctsw-vue\packages\app\src\layouts\default.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<script lang="ts" setup>
const route = useRoute()
const title = ref(route.query.title as string || '')
const router = useRouter()
function search() {
  router.push({
    name: 'search',
    query: { title: title.value },
  })
}

route.name === 'search' && watch(computed(() => route.query.title as string), (newVal) => {

  if (title.value !== newVal)
    title.value = newVal
})
</script>

<template>
  <LayoutHeader v-show="!$route.query.isSite">
    <template #nav>
      <div v-if="$route.name === 'search'" class="flex-center ml-5">
        <n-input-group>
          <n-input v-model:value="title" placeholder="请输入关键词并按“回车”键发起搜索" size="large" clearable :style="{ width: '400px' }" @keydown.enter="search" />
          <n-button size="large" type="primary" style="height: 40px; border-radius: 10px;" @click="search">
            搜索
          </n-button>
        </n-input-group>
      </div>
    </template>
  </LayoutHeader>
  <template v-if="$route.query.isSite">
    <div class="w-full  bg-slate-1 py-5">
      <div class="w-300 m-auto flex-center relative rounded h-30 gap-5 bg-red">
        <img class="h-20 bg-transparent" src="/logo.png">
        <span class="text-10 color-white">&</span>
        <img class="h-20 bg-transparent" src="/zljg_logo.png">
        <span class="text-3 color-white absolute bottom-1 right-1">中国质量监管&汽车投诉网共建投诉频道</span>
      </div>
    </div>
  </template>
  <main> <slot /></main>
  <slot name="footer">
    <LayoutFooter />
  </slot>
</template>
